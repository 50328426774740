import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module'
import './index.css';
import AppRoutes from './routes/AppRoutes';

import { AuthProvider } from './context/auth';

const tagManagerArgs = {
  gtmId: 'AW-11551127600'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider value={{ signed: false }}>
      <AppRoutes />
    </AuthProvider>
  </React.StrictMode>
);
