import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import '../login/login.css';

import AuthContext from '../../context/auth';

import iconeBordo from '../../assets/images/iconeBordo.png';

function LinkEmail() {

    const { aplicaValidacaoCodigo } = useContext(AuthContext);

    const queryParams = new URLSearchParams(window.location.search);
    const mode = queryParams.get('mode');
    const oobCode = queryParams.get('oobCode');
    const navigate = useNavigate();

    useEffect(() => {
        
        if(mode == 'resetPassword'){
            navigate("../resetaSenha?oobCode="+oobCode);
            return;
        }

        if(mode == 'verifyEmail'){
            aplicaValidacaoCodigo(oobCode).then(() => {
                navigate("../login");
            });
            return;
        }

        navigate("../");
        return;
    })

    return (
        
        <div className='loginDiv'>
            <div className='box'>
                <img 
                    className="imagemIcone"
                    src={iconeBordo} 
                    alt="Icone"
                />
            </div>
        </div>
    );
}

export default LinkEmail;
