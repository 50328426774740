import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThreeDot } from 'react-loading-indicators';
import './apresentacao.css';

import iconeSemFundo from '../../assets/images/iconeSemFundo.png';

import sommelierIniciante from '../../assets/images/sommelierIniciante.png';
import sommelierAvancado from '../../assets/images/sommelierAvancado.png';
import sommelierMaster from '../../assets/images/sommelierMaster.png';

import Botao from '../../components/botao';
import BotaoLogo from '../../components/botaoLogo';
import BotaoImagem from '../../components/botaoImagem';

import { listaProdutos } from '../../services/startSubscription';

function Apresentacao() {
  const navigate = useNavigate();
  const myRef = useRef();

  const [produtos, setProdutos] = useState([]);

  useEffect(() => {
    const pegaListagemProdutos = async () => {
      const response = await listaProdutos();

      // Ordenando produtos por valor
      setProdutos(response.filter(x => x.active).sort(function(a,b) {
        if(parseFloat(a.valorUnitario) < parseFloat(b.valorUnitario)) return -1;
        if(parseFloat(a.valorUnitario) > parseFloat(b.valorUnitario)) return 1;
        return 0;
      }));
    }

    pegaListagemProdutos();
  },[])

  return (
    <div className="apresentacao">
      <div className="imagemFundo">
        <img 
          className="iconeInicial"
          src={iconeSemFundo} 
          alt="icone"
        />
        <div className="sloganInicial">
          <p className="sloganInicialTxt">A degustação perfeita, sem sair de casa.</p>
        </div>
      </div>
      <div className="divLinha">
        <div>
          <p className="txtAssinaturaVinho" style={{fontSize: 30}}>ASSINATURA</p>
          <p className="txtAssinaturaVinho">DE VINHOS</p>
        </div>
        <div className="divAssinaturaVinho">
          <p className="txtAssinaturaVinhoDesc"><span>Os vinhos por assinatura, são pensados para garantir que você sempre tenha à disposição vinhos selecionados. </span></p>
          <p className="txtAssinaturaVinhoDesc"><span>A cada mês, você receberá em sua casa uma seleção exclusiva, acompanhada de uma carta elaborada para enriquecer seu conhecimento e tornar sua experiência de degustação ainda mais especial. </span></p>
          <p className="txtAssinaturaVinhoDesc"><span>Nossa missão é facilitar sua jornada pelo mundo dos vinhos, trazendo qualidade e praticidade para o seu dia a dia.</span></p>
        </div>
      </div>
      {/* <div 
        className="imagemFundoBarril"
        // src={barrisDeVinho} 
        // alt="Barris de vinho"
      /> */}
      <div className='divComoFunciona'>
        <div className="divLinhaComoFunciona">
          <div className="divComoFuncionaQuadrado">
            <span className="txtAssinaturaVinhoDescComoFunciona">COMO FUNCIONA ? </span>
          </div>
          <div className="divComoFuncionaQuadrado">
            <p><span className="txtAssinaturaVinhoDesc">{"• Escolha o pacote que mais te agradar! "}</span></p>
            <p><span className="txtAssinaturaVinhoDesc">{"• Faça seu cadastro. "}</span></p>
            <p><span className="txtAssinaturaVinhoDesc">{"• Informe local de entrega. "}</span></p>
            <p><span className="txtAssinaturaVinhoDesc">{"• Defina a forma de pagamento. "}</span></p>
            <p><span className="txtAssinaturaVinhoDesc">{"• Finalize sua assinatura. "}</span></p>
            <p><span className="txtAssinaturaVinhoDesc">{"• Receba no conforto de sua casa os vinhos que selecionamos para vocês. "}</span></p>
            <p><span className="txtAssinaturaVinhoDesc">{"• Aproveite a degustação! "}</span></p>
            <div style={{justifyContent: 'center', display: "flex"}}>
              <Botao
                colorButton={"#690B23"}
                onClick={() => {
                  myRef.current?.scrollIntoView()
                }}
                textoBotao={"Conheça nossos pacotes!"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='divEscolhaPacote' ref={myRef}>
        <span className='txtEscolhaPacote'>ESCOLHA SEU PACOTE</span>
        <div className='divLinhaPacotes'>
          {produtos.length > 0 
            ? produtos.map((element, index) => (
              <BotaoImagem
                onClick={() => {
                  navigate("/login", { state: element });
                }}
                imagemFundo={
                  element.nomeProduto == "Sommelier Iniciante" 
                    ? sommelierIniciante
                    : element.nomeProduto == "Sommelier Avançado" 
                      ? sommelierAvancado
                      : sommelierMaster
                }
                nomePacote={element.nomeProduto}
                valorPacote={element.valorUnitario}
                corBotao={index == 1 ? "#52091B" : "#690B23"}
              />

            )) 
            : 
            <div style={{width: "100%", marginBottom: 20}}>
              <ThreeDot variant="bounce" color="#ffffff" size="small" text="" textColor="" style={{alignSelf: "center"}} speedPlus={2}/>
            </div>
          }
        </div>
      </div>
      <div className='divLinhaContatos'>
        <div className='divContatos'>
          <div>
            <p><span className='txtGrande'>DEGUSTA</span></p>
            <p><span className='txtGrande'>FÁCIL</span></p>
          </div>
          <div>
            <p><span>contato@degustafacil.com.br</span></p>
            <p><span>{"(11) 98054-5156"}</span></p>
            <p><span>{"(11) 97051-3390"}</span></p>
          </div>
          <div style={{
            cursor: 'pointer'
          }}onClick={() => {
            navigate("/login");
          }}>
            <p><span className='txtMedio'>FAÇA SUA ASSINATURA</span></p>
            <p><span className='txtMedio'>AGORA MESMO!</span></p>
          </div>
        </div>
        <div
          className="imagemTacaContatos"
        />
      </div>

      <div className="botaoLogar">
        <BotaoLogo
          onClick={() => {
            navigate("/login");
          }}
        />
      </div>
    </div>
  );
}

export default Apresentacao;
