


import styles from './botaoCancelar.module.css';
import { FaRegTimesCircle, FaCheck } from "react-icons/fa";

export default function botaoCancelar(params) {
    return(
        <div 
            className={styles.botaoCancelarAssinatura}
            style={params.botaoVerde ? {backgroundColor: '#49bd64' } : {backgroundColor: '#f36363'}}
            onClick={() => {
                params.onClick();
            }}
        >
            <span style={{marginRight: 10}}>{params.textBotao}</span>
            {params.botaoVerde ? 
                <FaCheck 
                    color='#FFF'
                    size={30}
                />
            :
                <FaRegTimesCircle 
                    color='#FFF'
                    size={30}
                />
            }
        </div>
    )
}