import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ThreeDot } from 'react-loading-indicators';

import './login.css';

import iconeBordo from '../../assets/images/iconeBordo.png';

import InputText from '../../components/inputText';
import InputSenha from '../../components/inputSenha';
import Botao from '../../components/botao';

import AuthContext from '../../context/auth';

function ResetaSenha() {

    const queryParams = new URLSearchParams(window.location.search);
    const oobCode = queryParams.get('oobCode');

    const { enviaEmailResetaSenha, enviaResetaSenha } = useContext(AuthContext);
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [mensagemEmailEnviado, setMensagemEmailEnviado] = useState("");
    const [msgErroPreenchimento, setMsgErroPreenchimento] = useState("");
    const [carregandoClickBotao, setCarregandoClickBotao] = useState(false);

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handleSenha = (event) => {
        setSenha(event.target.value);
    }

    const criarNovaSenha = async () => {
        setCarregandoClickBotao(true);

        if(oobCode){
            const response = await enviaResetaSenha(senha, oobCode);

            if(!response.success){
                if(response.errorCode == "auth/invalid-password"){
                    setMsgErroPreenchimento("Senha inválida.");
                }

                if(response.errorCode == "auth/missing-password"){
                    setMsgErroPreenchimento("Senha inválida.");
                }
                
                setCarregandoClickBotao(false);
                return;
            }

            setCarregandoClickBotao(false);
            navigate("../login")
            return;
        }

        const response = await enviaEmailResetaSenha(email);

        if(!response.success){
            if(response.errorCode == "auth/invalid-email"){
                setMsgErroPreenchimento("E-mail inválido.");
            }

            if(response.errorCode == "auth/user-not-found"){
                setMsgErroPreenchimento("Usuário não encontrado.");
            }
            
            setCarregandoClickBotao(false);
            return;
        }

        setCarregandoClickBotao(false);
        setMensagemEmailEnviado("E-mail de recuperação de senha enviado!");
    }

    // Função que será chamada ao pressionar Enter
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // enviaDadosLogin();
        }
    };

    return (
        <div className='loginDiv'>
            <div className='box'>
                <img 
                    className="imagemIcone"
                    src={iconeBordo} 
                    alt="Icone"
                />

                {mensagemEmailEnviado != "" 
                ?   <div className='txtEmailEnviado'>
                        <span>{mensagemEmailEnviado}</span>
                    </div>
                :   <>
                        {!oobCode &&
                            <InputText value={email} onChange={handleEmail} placeholder={"E-mail"} />
                        }

                        {oobCode &&
                            <InputSenha 
                                value={senha} 
                                onChange={handleSenha} 
                                placeholder={"Nova senha"}
                                onKeyDown={handleKeyDown}
                            />
                        }
                        <div className="txtPreenchimentoIncorreto">
                            <span>{msgErroPreenchimento}</span>
                        </div>
                        {carregandoClickBotao 
                        ? <ThreeDot variant="bounce" color="#ffffff" size="small" text="" textColor="" speedPlus={2}/>
                        : <Botao
                            colorButton={"#1E1F20"}
                            onClick = {criarNovaSenha}
                            textoBotao = {oobCode ? 'Criar nova senha' : 'Recuperar senha'}
                        />
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default ResetaSenha;
