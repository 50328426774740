import React,{ useContext, useState } from 'react';

import AuthContext from '../../context/auth';

import styles from './confirmaDezoitoAnos.module.css';
import BotaoCancelar from '../../components/botaoCancelar';

import iconeBordo from '../../assets/images/iconeBordo.png';

function ConfirmaDezoitoAnos() {

    const { atualizarMensagem } = useContext(AuthContext);

    const [clicouNao, setClicouNao] = useState(false);

    return (
        <div className={styles.bodyCancelando}>
            <img 
                className={styles.imagemIcone}
                src={iconeBordo} 
                alt="Icone"
            />
            <span style={{fontSize: 30}}>Você tem 18 anos ou mais?</span>
            <div className={styles.linha}>
                <BotaoCancelar
                    onClick={() => atualizarMensagem(false)}
                    botaoVerde={true}
                    textBotao={"Sim"}
                />
                <BotaoCancelar
                    onClick={() => {setClicouNao(true)}}
                    textBotao={"Não"}
                />
            </div>
            {clicouNao && 
                <span style={{fontSize: 20}}>Este site é apenas para maiores de 18!</span>
            }
        </div>
    );
}

export default ConfirmaDezoitoAnos;
